<template>
  <v-card flat outlined class="rounded-10" min-height="100vh">
    <v-card-title class="py-3 primary--text rounded-bl-0 rounded-br-0">
      <v-row
        dense
        class="fw-600 fs-21 d-flex align-center justify-space-between"
      >
        <span>Manage Categories</span>
        <v-btn icon depressed @click="goBack">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row
        dense
        class="my-5"
        align="center"
        :class="{ 'flex-column': !mdAndUp }"
        justify="space-between"
      >
        <h3 class="primary--text my-1 fw-600">Select categories to display</h3>
        <div>
          <v-btn
            depressed
            class="my-1 mr-1 text-none"
            color="primary"
            @click="addMaincategory"
          >
            New category
          </v-btn>
          <v-btn
            depressed
            :disabled="selected_ids.length === 0"
            class="my-1 text-none"
            color="primary"
            @click="handleSaveChanges"
          >
            Save Changes
          </v-btn>
        </div>
      </v-row>
      <v-item-group
        v-model="selected"
        multiple
        v-if="settings_categories.length"
      >
        <v-sheet
          v-for="category in settings_categories"
          class="fullwidth mt-5"
          :key="category.id"
        >
          <v-row
            dense
            align="center"
            class="fill-height"
            :class="{ 'flex-column': !mdAndUp }"
          >
            <v-col :cols="mdAndUp ? 2 : 12">
              <v-item v-slot:default="{ active, toggle }">
                <v-card
                  flat
                  dark
                  :color="active ? 'primary' : 'grey'"
                  class="d-flex flex-column py-3 mb-3 rounded-8 align-center"
                  @click.stop="toggle"
                >
                  <div
                    class="
                      category-actions
                      d-flex
                      align-center
                      justify-space-between
                    "
                  >
                    <v-btn icon dark @click.stop="toggle">
                      <v-icon>
                        {{
                          active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank'
                        }}
                      </v-icon>
                    </v-btn>
                    <v-menu bottom left v-if="canActions(category)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="editCategory(category)">
                          <v-list-item-title class="primary--text subtitle-2">
                            <v-icon left color="primary">mdi-pencil</v-icon>
                            Edit Category
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="confirmDeleteCategory(category, false)"
                        >
                          <v-list-item-title class="primary--text subtitle-2">
                            <v-icon left color="error">mdi-delete</v-icon>
                            Delete Category
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <v-img
                    :lazy-src="settings.loader"
                    max-width="140"
                    contain
                    :src="category.icon"
                  ></v-img>
                  <h4 class="light--text fw-700">
                    {{ category.title }}
                  </h4>
                </v-card>
              </v-item>
            </v-col>
            <v-col :cols="mdAndUp ? 10 : 12">
              <v-row
                dense
                align="center"
                class="mb-1"
                :justify="mdAndUp ? 'space-between' : 'center'"
              >
                <h5
                  class="primary--text text-center mb-2 font-weight-bold px-4"
                >
                  Types Of Graphics for {{ category.title }}
                </h5>
                <div class="d-flex align-center justify-center">
                  <v-btn
                    :disabled="!canAddSubCategory(category)"
                    class="text-none mx-1 px-5"
                    depressed
                    small
                    @click="addSubCategory(category)"
                    outlined
                    color="primary"
                  >
                    New Type
                  </v-btn>
                  <v-btn
                    outlined
                    :disabled="!category.subcategories.length"
                    v-if="canManageSubCategory"
                    class="text-none px-5"
                    depressed
                    small
                    @click="manageSubcategories(category)"
                    color="primary"
                  >
                    Manage Types
                  </v-btn>
                </div>
              </v-row>
              <v-sheet
                height="140"
                color="#f2f1f7"
                class="fullwidth rounded-5 d-flex px-1 align-center"
              >
                <v-slide-group show-arrows v-if="category.subcategories.length">
                  <v-slide-item
                    v-for="sub in category.subcategories"
                    :key="sub.id"
                    class="d-flex align-center"
                  >
                    <v-tooltip color="primary" top>
                      <template
                        v-slot:activator="{
                          on: onTooltip,
                          attrs: attrsToolTip
                        }"
                      >
                        <v-card
                          v-bind="attrsToolTip"
                          v-on="onTooltip"
                          class="mx-3 align-self-center pa-2"
                          :color="sub.selected ? 'primary' : 'grey'"
                          width="120"
                        >
                          <div
                            class="
                              subcategory-actions
                              d-flex
                              align-center
                              justify-end
                            "
                          >
                            <v-menu bottom left v-if="canActions(sub)">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  dark
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mr-2"
                                >
                                  <v-icon>
                                    mdi-dots-vertical-circle-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-list dense>
                                <v-list-item
                                  @click="editSubCategory(category, sub)"
                                >
                                  <v-list-item-title
                                    class="primary--text subtitle-2"
                                  >
                                    <v-icon left color="primary">
                                      mdi-pencil
                                    </v-icon>
                                    Edit Type
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  @click="confirmDeleteCategory(sub, true)"
                                >
                                  <v-list-item-title
                                    class="primary--text subtitle-2"
                                  >
                                    <v-icon left color="error">
                                      mdi-delete
                                    </v-icon>
                                    Delete Type
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                          <v-img
                            :lazy-src="settings.loader"
                            contain
                            :src="sub.icon"
                          ></v-img>
                        </v-card>
                      </template>
                      <div class="d-flex flex-column">
                        <span class="light--text fw-900">
                          {{ sub.title }}
                        </span>
                        <span class="light--text fw-700">
                          Products: {{ sub.counts }}
                        </span>
                      </div>
                    </v-tooltip>
                  </v-slide-item>
                </v-slide-group>
                <v-alert
                  v-else
                  height="130"
                  class="
                    border-0
                    align-self-center
                    transparent
                    primary--text
                    fs-20
                    fw-500
                    d-flex
                    fullwidth
                  "
                >
                  No types of graphics found
                </v-alert>
              </v-sheet>
            </v-col>
          </v-row>
        </v-sheet>
      </v-item-group>
      <Empty v-else headline="No categories yet"></Empty>
    </v-card-text>
    <v-card-actions class="px-md-2 px-sm-1">
      <p class="caption secondary--text fw-400">
        <strong>Notes:</strong> Categories that doesn't have displayable types
        of graphics and products will not be displayed on Offers page.<br />
        Types of graphics that doesn't have products or graphics will also not
        display on Offers.
      </p>
    </v-card-actions>
    <CategoryDialog
      v-model="maincategory_dialog"
      :item="activeCategory"
      @update="handleUpdateMainCategory"
      @save="handleAddMainCategory"
    ></CategoryDialog>
    <GraphicTypeDialog
      v-model="subcategory_dialog"
      :category="activeCategory"
      :item="activeSubcategory"
      @update="handleUpdateSubCategory"
      @save="handleAddSubCategory"
    ></GraphicTypeDialog>
    <ManageSubcategoriesDialog
      v-model="manage_subcategory_dialog"
      :item="activeCategory"
      @save="handleSubcategoriesChanges"
    ></ManageSubcategoriesDialog>
    <DeleteDialog
      v-model="delete_category_dialog"
      :text-content="delete_text"
      @delete="handleDeleteCategory"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DeleteDialog from '@/common/DeleteDialog.vue'
import CategoryDialog from '../components/CategoryDialog.vue'
import ManageSubcategoriesDialog from '../components/ManageSubcategoriesDialog.vue'
import GraphicTypeDialog from '../components/GraphicTypeDialog.vue'
export default {
  components: {
    CategoryDialog,
    ManageSubcategoriesDialog,
    DeleteDialog,
    GraphicTypeDialog
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('cart', [('company_categories', 'settings_categories')]),
    selected_ids() {
      return this.settings_categories
        .filter((e, i) => this.selected.includes(i))
        .map((u) => u.id)
    }
  },
  data: () => ({
    selected: [],
    subselected: [],
    activeCategory: null,
    activeSubcategory: null,
    maincategory_dialog: false,
    subcategory_dialog: false,
    manage_subcategory_dialog: false,
    delete_category_dialog: false,
    delete_text: 'Are you sure you want to delete this item?'
  }),
  created() {
    this.fetchCompanyCategories()
    this.fetchSettingsCategories(() => this.refreshSettings())
  },
  methods: {
    ...mapActions('cart', [
      'fetchCompanyCategories',
      'fetchSettingsCategories',
      'saveSelectedDisplayCategories',
      'saveSelectedDisplaySubCategories',
      'createNewCategory',
      'createNewGraphicType',
      'updateCategory',
      'updateGraphicType',
      'deleteCategory',
      'deleteGraphicType'
    ]),
    refreshSettings() {
      this.selected = [...this.settings_categories.keys()].filter(
        (i) => this.settings_categories[i].selected
      )
    },
    goBack() {
      if (this.$route.query.hasOwnProperty('redirect_uri')) {
        this.$router.push({ path: this.$route.query.redirect_uri })
      } else {
        this.$router.go(-1)
      }
    },
    editCategory(category) {
      this.activeCategory = category
      this.maincategory_dialog = true
    },
    editSubCategory(category, subcategory) {
      this.activeCategory = category
      this.activeSubcategory = subcategory
      this.subcategory_dialog = true
    },
    confirmDeleteCategory(category, sub) {
      this.activeCategory = category
      this.delete_text =
        "Are you sure you want to delete this category? All types of graphics belongs to this category will also remove from Offers page. This can't be undone."
      if (sub) {
        this.delete_text =
          "Are you sure you want to delete this type of graphics? All graphics belongs to this type will also remove from Offers page. This can't be undone."
      }
      this.delete_category_dialog = true
    },
    canActions(category) {
      return (
        this.user.company.id === category.company_id &&
        (this.user.is_admin || this.$_permisssions.can('offers.create'))
      )
    },
    canAddCategory() {
      return this.user.is_admin || this.$_permisssions.can('offers.create')
    },
    canAddSubCategory(category) {
      return (
        this.user.company.id === category.company_id &&
        (this.user.is_admin || this.$_permisssions.can('offers.create'))
      )
    },
    canManageSubCategory() {
      return this.user.is_admin || this.$_permisssions.can('offers.create')
    },
    addMaincategory() {
      this.maincategory_dialog = true
      this.activeCategory = null
      this.activeSubcategory = null
    },
    addSubCategory(category) {
      this.activeCategory = category
      this.activeSubcategory = null
      this.subcategory_dialog = true
    },
    handleSaveChanges() {
      let payload = {
        ids: this.selected_ids
      }
      this.saveSelectedDisplayCategories({
        payload: payload,
        cb: () => {
          this.appSnackbar('Selected categories updated')
          this.refreshSettings()
        }
      })
    },
    handleSubcategoriesChanges(payload) {
      this.saveSelectedDisplaySubCategories({
        main: this.activeCategory.id,
        payload: payload,
        cb: () => {
          this.appSnackbar('Selected types of graphics updated')
          this.manage_subcategory_dialog = false
          this.refreshSettings()
        }
      })
    },
    manageSubcategories(category) {
      this.activeCategory = category
      this.activeSubcategory = category
      this.manage_subcategory_dialog = true
    },
    handleUpdateMainCategory(payload) {
      this.updateCategory({
        id: this.activeCategory.id,
        payload: payload,
        cb: () => {
          this.maincategory_dialog = false
          this.appSnackbar('Category updated')
          this.refreshSettings()
        }
      })
    },
    handleAddSubCategory(payload) {
      this.createNewGraphicType({
        payload: payload,
        cb: () => {
          this.subcategory_dialog = false
          this.appSnackbar('New type of graphics added')
          this.refreshSettings()
        }
      })
    },
    handleUpdateSubCategory(payload) {
      this.updateGraphicType({
        id: payload.id,
        payload: payload,
        cb: () => {
          this.subcategory_dialog = false
          this.appSnackbar('New type of graphics added')
          this.refreshSettings()
        }
      })
    },
    handleAddMainCategory(category) {
      this.createNewCategory({
        payload: category,
        cb: () => {
          this.maincategory_dialog = false
          this.appSnackbar('New category added')
          this.refreshSettings()
        }
      })
    },
    handleDeleteCategory() {
      this.deleteCategory({
        id: this.activeCategory.id,
        cb: () => {
          this.activeCategory = null
          this.delete_category_dialog = false
          this.appSnackbar('Category deleted')
          this.refreshSettings()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.category-actions {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 50px;
  width: 100%;
}
.subcategory-actions {
  position: absolute;
  top: 0;
  z-index: 3;
  height: 50px;
  width: 100%;
}
</style>
