<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    :title="title"
    button2-text="Save Changes"
    @button2="save"
    right-buttons
    :max-width="1024"
  >
    <template v-slot:content>
      <v-card flat class="manage-subcategories">
        <v-item-group v-model="selected" multiple>
          <v-container>
            <h3
              class="mb-3 text-center fw-600 primary--text"
              v-if="item && item.subcategories && item.subcategories.length"
            >
              Select graphic types to display
            </h3>
            <v-row
              dense
              align="center"
              justify="start"
              class="flex-wrap"
              v-if="item && item.subcategories && item.subcategories.length"
            >
              <v-col
                v-for="sub in item.subcategories"
                :key="sub.id"
                cols="12"
                lg="2"
                md="3"
                sm="4"
              >
                <v-item v-slot:default="{ active, toggle }">
                  <v-badge
                    class="d-flex align-center justify-center flex-column ma-2"
                    color="primary"
                    :icon="
                      active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank'
                    "
                    offset-x="30"
                    offset-y="30"
                    overlap
                  >
                    <v-card
                      class="pa-2"
                      dark
                      :color="active ? 'primary' : 'grey'"
                      height="200"
                      width="200"
                      @click="toggle"
                    >
                      <v-img
                        :lazy-src="settings.loader"
                        max-width="120"
                        contain
                        :src="sub.icon"
                      ></v-img>
                      <h4 class="light--text text-center mt-2 fw-700">
                        {{ sub.title }}
                      </h4>
                    </v-card>
                  </v-badge>
                </v-item>
              </v-col>
            </v-row>
            <Empty v-else headline="No types of graphics found"></Empty>
          </v-container>
        </v-item-group>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  name: '',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object }
  },
  computed: {
    title() {
      return this.item
        ? `Types of graphics for ${this.item.title}`
        : 'Types of graphics'
    },
    selected_ids() {
      if (!this.item.subcategories || this.item.subcategories.length < 1) {
        return []
      }
      return this.item.subcategories
        .filter((e, i) => this.selected.includes(i))
        .map((u) => u.id)
    }
  },
  data: () => ({
    dialog: false,
    selected: []
  }),
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    },
    item: {
      handler: function (val) {
        if (
          val &&
          val.hasOwnProperty('subcategories') &&
          val.subcategories &&
          val.subcategories.length
        ) {
          this.selected = [...val.subcategories.keys()].filter(
            (i) => val.subcategories[i].selected
          )
        } else {
          this.selected = []
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    save() {
      this.$emit('save', {
        ids: this.selected_ids
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.category-actions {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 50px;
  width: 100%;
}
</style>
<style lang="scss">
.manage-subcategories {
  .v-badge__badge {
    height: 30px;
    width: 30px;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    padding: 9px;
    .v-icon {
      font-size: 20px;
    }
  }
}
</style>
